import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Col, Card, CardHeader, Table, Form, FormGroup, Label, Input, ModalFooter, Row } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { useDispatch, useSelector } from 'react-redux';
import CommonModal from '../../Components/Modals/modal';
import Pagination from '../../Components/Pagination/Pagination';
import Empty from '../../Components/Empty/Index';
import moment from 'moment';
import { getastrologer, isOpenStatusModal, statusToggle, examToggle, statusUpdateastrologer, ModalToggle, isOpenModal, updateAstrologer, isOpenExamStatusModal, statusUpdateExamAstrologer, takeExamAstrologer, isOpenVideoExamStatusModal, videoExamToggle, statusUpdateVideoExamAstrologer, } from '../../store/astrologerSlice';
import { useNavigate } from 'react-router';
import CustomizerContext from '../../_helper/Customizer';
import SweetAlert from 'sweetalert2';


const AstrologersTable = () => {
  const storeVar = useSelector(state => state.astrologer)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toggle = () => dispatch(ModalToggle());
  const { layoutURL } = useContext(CustomizerContext);
  const statusModalToggle = () => dispatch(statusToggle());
  const examModalToggle = () => dispatch(examToggle());
  const videoExamModalToggle = () => dispatch(videoExamToggle());
  const [stateStatus, setStateStatus] = useState('active');
  const [submit, setSubmit] = useState(false);
  const [typingTimer, setTypingTimer] = useState(null);
  const typingDelay = 800;

  const [formVar, setFormVar] = useState({
    keyword: '',
    limit: 50,
    offset: 0,
    currentPage: 1,
    status: 'active',
    modalTitle: null,
    editState: false,
    astrologerId: '',
    level: '',
    adminCommission: '',
    pricePerMinute: '',
    examStatus: '',
    videoExamStatus: '',
    chatPrice: '',
    callPrice: '',
    videoPrice: '',
  });

  useEffect(() => {
    dispatch(getastrologer(formVar.limit, formVar.offset, formVar.status, formVar.keyword))
  }, []);


  const pageChange = (page) => {
    const offset = formVar.limit * (page - 1)
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      currentPage: page,
      offset: offset
    }))
    dispatch(getastrologer(formVar.limit, offset, formVar.status, formVar.keyword))
  };
  const searchState = (e) => {
    setFormVar((prevFormVar) => ({ ...prevFormVar, keyword: e.target.value }))
    searchWithDelay(e.target.value)
  }
  const searchWithDelay = (keyword) => {
    clearTimeout(typingTimer);
    const timer = setTimeout(() => {
      dispatch(getastrologer(formVar.limit, formVar.offset, formVar.status, keyword))
    }, typingDelay);
    setTypingTimer(timer);
  };
  const handleInputChange = (e) => {
    setFormVar((prevFormVar) => ({ ...prevFormVar, status: e.target.value }))
    dispatch(getastrologer(formVar.limit, formVar.offset, e.target.value, formVar.keyword))
  };
  const EditToggleModal = (data) => {
    dispatch(isOpenModal(true))
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      editState: true,
      astrologerId: data.id,
      modalTitle: 'Edit Astrologer Details',
      level: data.level,
      adminCommission: data.adminCommission,
      chatPrice: data.chatPrice,
      callPrice: data.callPrice,
      videoPrice: data.videoPrice,

    }))
  }

  const onValueChange = (event) => {
    setStateStatus(event.target.value)
  }
  const statusToggleModal = (data) => {
    dispatch(isOpenStatusModal(true))
    setStateStatus(data.status)
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      categoryId: data.id,
    }))
  }
  const examStatusToggleModal = (data) => {
    dispatch(isOpenExamStatusModal(true))
    setStateStatus(data.status)
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      categoryId: data.id,
    }))
  }
  const videoExamStatusToggleModal = (data) => {
    dispatch(isOpenVideoExamStatusModal(true))
    setStateStatus(data.status)
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      categoryId: data.id,
    }))
  }

  const submitAstrologer = () => {
    if (LevelValid() || commissionValid() || chatPricePerMinuteValid() || callPricePerMinuteValid() || videoPricePerMinuteValid()) {
      setSubmit(true)
      return null
    }
    setSubmit(false)
    dispatch(updateAstrologer(formVar.astrologerId, { chatPrice: formVar.chatPrice, callPrice: formVar.callPrice, videoPrice: formVar.videoPrice, adminCommission: formVar.adminCommission, level: formVar.level }))

  }
  const submitStatus = () => {
    dispatch(statusUpdateastrologer({ id: formVar.categoryId, status: stateStatus }))
  }
  const submitExamStatus = () => {
    dispatch(statusUpdateExamAstrologer({ id: formVar.categoryId, status: stateStatus }))
  }
  const submitVideoExamStatus = () => {
    dispatch(statusUpdateVideoExamAstrologer({ id: formVar.categoryId, status: formVar.videoExamStatus }))
  }

  const LevelValid = () => {
    if (!formVar.level) {
      return "Level is required";
    }
  }
  const commissionValid = () => {
    if (!formVar.adminCommission) {
      return "Admin Commission is required";
    }
  }
  const chatPricePerMinuteValid = () => {
    if (!formVar.chatPrice) {
      return "Admin Commission is required";
    }
  }
  const callPricePerMinuteValid = () => {
    if (!formVar.callPrice) {
      return "Admin Commission is required";
    }
  }
  const videoPricePerMinuteValid = () => {
    if (!formVar.videoPrice) {
      return "Admin Commission is required";
    }
  }

  const takeExam = (data) => {
    SweetAlert.fire({
      title: 'Are you sure?',
      text: 'You want to enable exam for this astrologer!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Cancel',
      reverseButtons: true
    })
      .then((result) => {
        if (result.value) {
          dispatch(takeExamAstrologer({ id: data.id, status: 'active' }))
        }
      });
  }

  return (
    <Fragment>
      <Col sm='12'>
        <Card>
          <CardHeader>
            <Row>
              <Col md="6">
                <Input className="form-control" placeholder='Serch..' type="text" id="yourInputId"
                  value={formVar.keyword} onChange={(e) => searchState(e)}
                />
              </Col>
              <Col md="4">
                <Input className="form-control form-control-inverse btn-square" name="select" type="select"
                  value={formVar.status} onChange={handleInputChange}>
                  <option value='active'>ACTIVE</option>
                  <option value='pending'>PENDING</option>
                </Input>
              </Col>
            </Row>

          </CardHeader>
          <div className='table-responsive'>
            <Table hover={true} className='table-border-horizontal table-light'>
              <thead>
                <tr>
                  <th scope='col'>Sl.No</th>
                  <th scope='col'>Name</th>
                  <th scope='col'>Phone Number</th>
                  <th scope='col'>Wallet</th>
                  <th scope='col'>Level</th>
                  <th scope='col'>Admin Commission</th>
                  <th scope='col'>Score</th>
                  <th scope='col'>Joining Date</th>
                  <th scope='col'>Written Exam</th>
                  <th scope='col'>Video Exam</th>
                  <th scope='col'>Status</th>
                  <th scope='col'>Action</th>
                </tr>
              </thead>
              <tbody>
                {storeVar.astrologerData?.map((item, index) => (
                  <tr key={item.id}>
                    <th scope='row'>{index + 1}</th>
                    <td>{item.name}</td>
                    <td>{item.phoneNumber}</td>
                    <td>{"₹" + item.wallet}</td>
                    <td>{item.level}</td>
                    <td>{(item.adminCommission || 0) + "%"}</td>
                    <td>{item.examScore}</td>
                    <td>{moment(item.createdAt).format('Do MMM YYYY')}</td>
                    <td>
                      {
                        item.examScoreApproved === 'approved' && <>
                          <span className={`font-success rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.examScoreApproved === 'approved' && (
                              <i className="fa fa-check-circle-o fa-lg" aria-hidden="true"></i>
                            )}
                            &nbsp; <span>{item.examScoreApproved.toUpperCase()}</span>
                          </span>
                        </>
                      }
                      {
                        item.examScoreApproved === 'active' && <>
                          <span className={`font-success rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.examScoreApproved === 'active' && (
                              <i className="fa fa-check-circle-o fa-lg" aria-hidden="true"></i>
                            )}
                            &nbsp; <span>{item.examScoreApproved.toUpperCase()}</span>
                          </span>
                        </>
                      }
                      {
                        item.examScoreApproved === 'pending' && <>
                          <span className={`font-secondary rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.examScoreApproved === 'pending' && (
                              <i className="fa fa-clock-o fa-lg" aria-hidden="true"></i>
                            )}
                            &nbsp; <span>NEW</span>
                          </span>
                        </>
                      }
                      {
                        item.examScoreApproved === 'given' && <>
                          <span className={`font-warning rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.examScoreApproved === 'given' && (
                              <i className="fa fa-clock-o fa-lg" aria-hidden="true"></i>
                            )}
                            &nbsp; <span>EXAMDONE</span>
                          </span>
                        </>
                      }
                      {
                        item.examScoreApproved === 'rejected' && <>
                          <span className={`font-warning rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.examScoreApproved === 'rejected' && (
                              <i className="fa fa-clock-o fa-lg" aria-hidden="true"></i>
                            )}
                            &nbsp; <span>FAIL</span>
                          </span>
                        </>
                      }
                    </td>
                    <td>
                      {
                        item.videocallApproved === 'approved' && <>
                          <span className={`font-success rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.videocallApproved === 'approved' && (
                              <i className="fa fa-check-circle-o fa-lg" aria-hidden="true"></i>
                            )}
                            &nbsp; <span>{item.videocallApproved.toUpperCase()}</span>
                          </span>
                        </>
                      }
                      {
                        item.videocallApproved === 'pending' && <>
                          <span className={`font-secondary rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.videocallApproved === 'pending' && (
                              <i className="fa fa-clock-o fa-lg" aria-hidden="true"></i>
                            )}
                            &nbsp; <span>NEW</span>
                          </span>
                        </>
                      }

                      {
                        item.examScoreApproved === 'rejected' && <>
                          <span className={`font-warning rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.examScoreApproved === 'rejected' && (
                              <i className="fa fa-clock-o fa-lg" aria-hidden="true"></i>
                            )}
                            &nbsp; <span>FAIL</span>
                          </span>
                        </>
                      }
                    </td>
                    <td>
                      {
                        item.status === 'active' && <>
                          <span className={`font-success rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'active' && (
                              <i className="fa fa-check-circle-o fa-lg" aria-hidden="true"></i>
                            )}
                            &nbsp; <span>{item.status.toUpperCase()}</span>
                          </span>
                        </>
                      }
                      {
                        item.status === 'pending' && <>
                          <span className={`font-warning rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'pending' && (
                              <i className="fa fa-clock-o fa-lg" aria-hidden="true"></i>
                            )}
                            &nbsp; <span>{item.status.toUpperCase()}</span>
                          </span>
                        </>
                      }
                    </td>
                    <td>
                      <div className='d-flex gap-2 align-items-center'>
                        <div className='cursor-pointer bg-light-primary font-primary action-icon'>
                          <i className="fa fa-info-circle fa-lg" onClick={(e) => navigate('/astrologers/astroDetails/' + layoutURL + '?id=' + item.id)}></i>
                          <div className="tooltipCustom">More Details</div>
                        </div>
                        <div className='cursor-pointer bg-light-info font-info action-icon'>
                          <i className="fa fa-gamepad fa-lg" onClick={item.examScoreApproved === 'pending' ? (e) => takeExam(item) : null}
                            style={{ cursor: item.examScoreApproved === 'pending' ? 'pointer' : 'not-allowed' }}></i>
                          <div className="tooltipCustom">Take Exam</div>
                        </div>
                        {/* <div className='cursor-pointer bg-light-primary font-secondary action-icon'>
                          <i className="fa fa-book fa-lg" onClick={(e) => navigate('/astrologers/examDetails/' + layoutURL + '?id=' + item.id)}></i>
                          <div className="tooltipCustom">Exam Details</div>
                        </div> */}
                        <div className='cursor-pointer bg-light-info font-success action-icon'
                          style={{ cursor: item.examScoreApproved === 'given' ? 'pointer' : 'not-allowed' }}>
                          <i className="fa fa-toggle-on fa-lg" onClick={item.examScoreApproved === 'given' ? (e) => examStatusToggleModal(item) : null}></i>
                          <div className="tooltipCustom">Exam Status Update</div>
                        </div>
                        <div className='cursor-pointer bg-light-primary font-primary action-icon'>
                          <i className="fa fa-video-camera fa-lg" onClick={(e) => videoExamStatusToggleModal(item)}></i>
                          <div className="tooltipCustom">Video Exam Status</div>
                        </div>
                        <div className='cursor-pointer bg-light-primary font-warning action-icon'>
                          <i className="fa fa-edit fa-lg" onClick={(e) => EditToggleModal(item)}></i>
                          <div className="tooltipCustom">Edit Profile</div>
                        </div>
                        {/* <div className='cursor-pointer bg-light-info font-info action-icon'>
                          <i className="fa fa-toggle-on fa-lg" onClick={(e) => statusToggleModal(item)}></i>
                          <div className="tooltipCustom">Status Update</div>
                        </div> */}
                        <div className='cursor-pointer bg-light-info font-info action-icon'>
                          <i
                            className="fa fa-toggle-on fa-lg"
                            onClick={(e) => {
                              if (item.level && item.adminCommission !== undefined && item.adminCommission !== null && item.examScore !== undefined && item.examScore !== null && item.examScore !== '') {
                                statusToggleModal(item);
                              } 
                            }}
                            style={{
                              opacity: (item.level && item.adminCommission !== undefined && item.adminCommission !== null && item.examScore !== undefined && item.examScore !== null && item.examScore !== '') ? 1 : 0.5,
                              cursor: (item.level && item.adminCommission !== undefined && item.adminCommission !== null && item.examScore !== undefined && item.examScore !== null && item.examScore !== '') ? 'pointer' : 'not-allowed' 
                            }}
                          >
                          </i>
                          <div className="tooltipCustom">
                            {(item.level && item.adminCommission !== undefined && item.adminCommission !== null && item.examScore !== undefined && item.examScore !== null && item.examScore !== '')
                              ? "Status Update"
                              : "Please fill all the details first"}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          {
            storeVar.totalastrologer <= 0 && (
              <Empty />
            )
          }
        </Card>
        {
          storeVar.totalastrologer > 0 &&
          <Pagination currentPage={formVar.currentPage} totalItem={storeVar.totalastrologer}
            itemsPerPage={formVar.limit} showEllipsisAfter={true} visiblePageCount={3} onPageChange={pageChange} />
        }
      </Col>
      <CommonModal isOpen={storeVar.isOpenModal} title={formVar.modalTitle} toggler={toggle} >
        <Form>
          <FormGroup>
            <Col >
              <Label className="col-form-label" for="recipient-name">Level</Label>
              <Input className="form-control form-control-inverse btn-square" name="select" type="select"
                value={formVar.level} onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, level: e.target.value }))}>
                <option value=''>Select Level</option>
                <option value='1'>1</option>
                <option value='2'>2</option>
                <option value='3'>3</option>
              </Input>
              {submit && LevelValid() ? <span className='d-block font-danger'>{LevelValid()}</span> : ""}
            </Col>
            <Col >
              <Label className="col-form-label" for="recipient-name">Admin Commission %</Label>
              <Input className="form-control" type="text" placeholder='Enter Commission in %'
                onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, "").replace(" ", "").slice(0, 2)}
                onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, adminCommission: e.target.value }))}
                value={formVar.adminCommission} />
              {submit && commissionValid() ? <span className='d-block font-danger'>{commissionValid()}</span> : ""}
            </Col>
            <Col >
              <Label className="col-form-label" for="recipient-name">Chat Price</Label>
              <Input className="form-control" type="text" placeholder='Enter Chat Per Minute Charges'
                onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, "").replace(" ", "").slice(0, 4)}
                onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, chatPrice: e.target.value }))}
                value={formVar.chatPrice} />
              {submit && chatPricePerMinuteValid() ? <span className='d-block font-danger'>{chatPricePerMinuteValid()}</span> : ""}
            </Col>
            <Col >
              <Label className="col-form-label" for="recipient-name">Call Price</Label>
              <Input className="form-control" type="text" placeholder='Enter Per Minute Charges'
                onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, "").replace(" ", "").slice(0, 4)}
                onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, callPrice: e.target.value }))}
                value={formVar.callPrice} />
              {submit && callPricePerMinuteValid() ? <span className='d-block font-danger'>{callPricePerMinuteValid()}</span> : ""}
            </Col>
            <Col >
              <Label className="col-form-label" for="recipient-name">Video Call Price</Label>
              <Input className="form-control" type="text" placeholder='Enter Per Minute Charges'
                onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, "").replace(" ", "").slice(0, 4)}
                onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, videoPrice: e.target.value }))}
                value={formVar.videoPrice} />
              {submit && videoPricePerMinuteValid() ? <span className='d-block font-danger'>{videoPricePerMinuteValid()}</span> : ""}
            </Col>
          </FormGroup>
        </Form>
        <ModalFooter>
          <Btn attrBtn={{ color: 'secondary', onClick: toggle }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitAstrologer }}>Save Changes</Btn>
        </ModalFooter>
      </CommonModal>
      <CommonModal isOpen={storeVar.isStatusOpenModal} title={'Status'} toggler={statusModalToggle} >
        <Col>
          <div className='d-flex m-15 m-checkbox-inline justify-content-center custom-radio-ml'>
            <div className='radio radio-primary'>
              <Input id='radioinline1' type='radio' className="radio_animated" name='radio1' checked={stateStatus === 'active'} onChange={onValueChange} value='active' />
              <Label className='mb-0' for='radioinline1'>
                <span className='digits'>ACTIVE</span>
              </Label>
            </div>

            <div className='radio radio-primary'>
              <Input id='radioinline3' type='radio' className="radio_animated" name='radio3' checked={stateStatus === 'pending'} onChange={onValueChange} value='pending' />
              <Label className='mb-0' for='radioinline3'>
                <span className='digits'>PENDING</span>
              </Label>
            </div>
          </div>
        </Col>
        <ModalFooter className='justify-content-center'>
          <Btn attrBtn={{ color: 'secondary', onClick: statusModalToggle }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitStatus }}>Save Changes</Btn>
        </ModalFooter>
      </CommonModal>
      <CommonModal isOpen={storeVar.isexamStatusOpenModal} title={'Exam Status'} toggler={examModalToggle} >
        <Col>
          <div className='d-flex m-15 m-checkbox-inline justify-content-center custom-radio-ml'>
            <div className='radio radio-primary'>
              <Input id='radioinline1' type='radio' className="radio_animated" name='radio1' onChange={onValueChange} value='approved' />
              <Label className='mb-0' for='radioinline1'>
                <span className='digits'>APPROVE</span>
              </Label>
            </div>

            <div className='radio radio-primary'>
              <Input id='radioinline3' type='radio' className="radio_animated" name='radio3' onChange={onValueChange} value='rejected' />
              <Label className='mb-0' for='radioinline3'>
                <span className='digits'>REJECT</span>
              </Label>
            </div>
          </div>
        </Col>
        <ModalFooter className='justify-content-center'>
          <Btn attrBtn={{ color: 'secondary', onClick: statusModalToggle }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitExamStatus }}>Save Changes</Btn>
        </ModalFooter>
      </CommonModal>
      <CommonModal isOpen={storeVar.isvideoExamStatusOpenModal} title={'Video Exam Status'} toggler={videoExamModalToggle} >
        <Col>
          <div className='d-flex m-15 m-checkbox-inline justify-content-center custom-radio-ml'>
            <div className='radio radio-primary'>
              <Input id='radioinline1' type='radio' className="radio_animated" name='radio1'
                onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, videoExamStatus: e.target.value }))}
                value='approved' />
              <Label className='mb-0' for='radioinline1'>
                <span className='digits'>APPROVE</span>
              </Label>
            </div>

            <div className='radio radio-primary'>
              <Input id='radioinline3' type='radio' className="radio_animated" name='radio3'
                onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, videoExamStatus: e.target.value }))}
                value='rejected' />
              <Label className='mb-0' for='radioinline3'>
                <span className='digits'>REJECT</span>
              </Label>
            </div>
          </div>
        </Col>
        <ModalFooter className='justify-content-center'>
          <Btn attrBtn={{ color: 'secondary', onClick: statusModalToggle }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitVideoExamStatus }}>Save Changes</Btn>
        </ModalFooter>
      </CommonModal>

    </Fragment>
  );
};

export default AstrologersTable;
