import React, { Fragment, useEffect } from 'react';
import { Col, Card, Table, Row, Label, CardBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getastrologerById } from '../../store/astrologerSlice';
import { getbankDetailsList } from '../../store/bankDetailsSlice';
import NoImage from '../../assets/images/noimage.png';


const AstroDetailsTable = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const storeVar = useSelector(state => state.astrologer)
  const bankDetailsVar = useSelector(state => state.bankDetails)
  console.log(storeVar);

  const astrlogerId = new URLSearchParams(location.search).get('id');

  useEffect(() => {
    dispatch(getastrologerById(astrlogerId))
    dispatch(getbankDetailsList(astrlogerId))
  }, []);

  return (
    <Fragment>
      <Card>
        <CardBody>
          <Label className="col-form-label font-bold" for="recipient-name">Details</Label>
          <Row >
            <Col md="4" className='h-2-r' >

              {"Name:- " + storeVar.astrologerDetails.name}
            </Col>
            <Col md="4" className='h-2-r'>
              {"Email:- " + storeVar.astrologerDetails.email}
            </Col>
            <Col md="4" className='h-2-r'>
              {"Phone Number:- " + storeVar.astrologerDetails?.phoneNumber}
            </Col>
            <Col md="4" className='h-2-r'>
              {"Level:- " + storeVar.astrologerDetails.level  || ''}
            </Col>
            <Col md="4" className='h-2-r'>
              {"Price Per Minute:- ₹" + storeVar.astrologerDetails.pricePerMinute}
            </Col>
            <Col md="4" className='h-2-r'>
              {"Admin Commission:- " + storeVar.astrologerDetails.adminCommission + "%"}
            </Col>
            <Col md="4" className='h-2-r'>
              {"Experience:- " + storeVar.astrologerDetails?.experience + "Yr"}
            </Col>
          </Row>

          <Label className="col-form-label font-bold" for="recipient-name">Bank Details</Label>
          <Row>
            <Col md="4" className='h-2-r'>
              {"Account Holder Name:- " + (bankDetailsVar.bankDetailsData.name || '')}
            </Col>
            <Col md="4" className='h-2-r'>
              {"Account No:- " + (bankDetailsVar.bankDetailsData.accountNo || '')}
            </Col>
            <Col md="4" className='h-2-r'>
              {"IFSC code:- " + (bankDetailsVar.bankDetailsData.ifsc || '')}
            </Col>
            <Col md="4" className='h-2-r'>
              {"Bank Name:- " + (bankDetailsVar.bankDetailsData.bankname || '')}
            </Col>
            <Col md="4" className='h-2-r'>
              {"Branch Name:- " + (bankDetailsVar.bankDetailsData.branchName || '')}
            </Col>
          </Row>

          <Label className="col-form-label font-bold" for="recipient-name">Images</Label>
          <Row className='h-10-r'>
            <Col md="4" className='h-2-r ' >
              {"Profile:- "}
              {storeVar.astrologerDetails.profile ? <img className='w-10-r h-5-r' src={storeVar.astrologerDetails.profile} alt="" /> : <img className='w-10-r h-5-r' src={NoImage} alt="" />}
            </Col>
            <Col md="4" className='h-2-r'>
              {"Aadhar Card:- "}
              {storeVar.astrologerDetails.profile ? <img className='w-10-r h-5-r' src={storeVar.astrologerDetails.aadharImage} alt="" /> : <img className='w-10-r h-5-r' src={NoImage} alt="" />}
            </Col>
            <Col md="4" className='h-2-r'>
              {"Pancard:- "}
              {storeVar.astrologerDetails.profile ? <img className='w-10-r h-5-r' src={storeVar.astrologerDetails.pancardImage} alt="" /> : <img className='w-10-r h-5-r' src={NoImage} alt="" />}
            </Col>
            <Col md="4" className='h-2-r'>
              {"Bank/ Cancelled Cheque:- "}
              {storeVar.astrologerDetails.profile ? <img className='w-10-r h-5-r' src={storeVar.astrologerDetails.profile} alt="" /> : <img className='w-10-r h-5-r' src={NoImage} alt="" />}

            </Col>
            {/* <Col md="4" className='h-2-r'>
              {"Pancard:- " + storeVar.astrologerDetails.email}
            </Col> */}
          </Row>
          <Label className="col-form-label font-bold" for="recipient-name">Specialization</Label>
          <Row className='h-10-r'>
            <Col md="4" className='h-2-r ' >
              {"Specialization:- "}
              {storeVar.astrologerDetails.specializations?.map((item, index) => (
                <Col>
                  {item.name}
                </Col>
              )
              )}

            </Col>
            </Row>


        </CardBody>

      </Card>
    </Fragment>
  );
};

export default AstroDetailsTable;
