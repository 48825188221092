import axios from "axios";
import { authHeader } from "../_helper/auth-header";



// const rootUrl = 'http://192.168.122.54:7000/api/v1/'
// const rootUrl = 'http://localhost:7000/api/v1/'
const rootUrl = 'https://server.astrrovidhaan.in/api/v1/'

const authURL = rootUrl + 'auth';
const dashboardURL = rootUrl + 'dashboard';
const astrologerURL = rootUrl + 'auth/astrologers';
const astroProfileURL = rootUrl + 'astroProfile';
const specializationURL = rootUrl + 'specialization';
const categoryURL = rootUrl + "category"
const subCategoryURL = rootUrl + "subCategory"
const productsURL = rootUrl + 'products';
const examDetailURL = rootUrl + 'examDetails';
const questionsURL = rootUrl + 'question';
const answersURL = rootUrl + 'answers';
const blogsUrl = rootUrl + 'blogs/';
const bannersUrl = rootUrl + 'banners';
const couponsUrl = rootUrl + 'coupons';
const podcastUrl = rootUrl + 'podcast';
const queryUrl = rootUrl + 'query';
const orderURL = rootUrl + 'cart';
const transactionUrl = rootUrl + 'transaction';
const bankDetailsURL = rootUrl + 'bankDetails';



/*********** Auth ***********/
async function login(loginData) {
  return await axios.post(authURL + '/admin/login', loginData);
}
async function getDashboard() {
  return await axios.get(dashboardURL, { headers: await authHeader() });
}



/*********** Astrologer ***********/

async function getastrologer(limit, offset, status, keyword) {
  return await axios.get(astrologerURL + '?limit=' + limit + '&offset=' + offset + '&status=' + status + '&keyword=' + keyword, {
    headers: await authHeader()
  });
}

async function statusUpdateastrologer(id, status) {
  return await axios.put(astrologerURL + '/' + id, { status }, {
    headers: await authHeader()
  });
}
async function statusUpdateExamAstrologer(id, status) {
  return await axios.patch(rootUrl + 'astroProfile/astroExam/' + id, { status }, {
    headers: await authHeader()
  });
}
async function statusUpdateVideoExamAstrologer(id, status) {
  return await axios.patch(rootUrl + 'astroProfile/videoExam/' + id, { status }, {
    headers: await authHeader()
  });
}


async function updateastrologer(id, data) {
  return await axios.patch(astroProfileURL + '/astro/' + id, data, {
    headers: await authHeader()
  });
}
async function fetchAstrologerById(id) {
  return await axios.get(authURL + '/admin/astrologerById/' + id, {
    headers: await authHeader()
  });
}

/*********** Specialization ***********/

async function getexamDetails(id) {
  return await axios.get(examDetailURL + '/' + id, {
    headers: await authHeader()
  });
}


/*********** Specialization ***********/

async function getSpecialization() {
  return await axios.get(specializationURL, {
    headers: await authHeader()
  });
}

async function addspecialization(data) {
  return await axios.post(specializationURL, data, {
    headers: await authHeader()
  });
}

async function updateSpecialization(id, data) {
  return await axios.patch(specializationURL + '/' + id, data, {
    headers: await authHeader()
  });
}
async function deleteSpecialization(id) {
  return await axios.delete(specializationURL + '/' + id, {
    headers: await authHeader()
  });
}

/********* CATEGORY *********/
async function getCategory(limit, offset, status, keyword) {
  return await axios.get(categoryURL + '/admin?limit=' + limit + '&offset=' + offset + '&status=' + status + '&keyword=' + keyword, {
    headers: await authHeader(),
  });
}
async function addCategory(payload) {
  return await axios.post(categoryURL, payload, {
    headers: await authHeader(),
  });
}
async function updateCategory(id, payload) {
  return await axios.patch(categoryURL + '/' + id, payload, {
    headers: await authHeader(),
  });
}
async function statusUpdateCategory(id, status) {
  return await axios.put(categoryURL + '/' + id, { status }, {
    headers: await authHeader(),
  });
}
async function updateCategoryImage(id, image) {
  return await axios.put(categoryURL + '/image/' + id, { image }, {
    headers: await authHeader('FormData'),
  });
}



/********* SUBCATEGORY *********/
async function getsubCategory(limit, offset, status, keyword) {
  return await axios.get(subCategoryURL + '?limit=' + limit + '&offset=' + offset + '&status=' + status + '&keyword=' + keyword, {
    headers: await authHeader(),
  });
}

async function addSubCategory(payload) {
  return await axios.post(subCategoryURL, payload, {
    headers: await authHeader(),
  });
}
async function updatesubCategory(id, payload) {
  return await axios.patch(subCategoryURL + '/' + id, payload, {
    headers: await authHeader(),
  });
}
async function statusUpdatesubCategory(id, status) {
  return await axios.put(subCategoryURL + '/' + id, { status }, {
    headers: await authHeader(),
  });
}


/********* PRODUCTS *********/
async function getproducts(limit, offset, status, keyword) {
  return await axios.get(productsURL + '/admin?limit=' + limit + '&offset=' + offset + '&status=' + status + '&keyword=' + keyword, {
    headers: await authHeader(),
  });
}

async function UpdateStatusProducts(data) {
  return await axios.put(productsURL + '/status', data, {
    headers: await authHeader(),
  });
}



/********* Questions *********/

async function fetchQuestions(examId) {
  return await axios.get(questionsURL + '/' + examId, {
    headers: await authHeader(),
  });
}

async function addquestion(data) {
  return await axios.post(questionsURL, data, {
    headers: await authHeader(),
  });
}

async function deleteQuestion(examId) {
  return await axios.delete(questionsURL + '/' + examId, {
    headers: await authHeader(),
  });
}

/********* Questions *********/

async function fetchanswers(examId) {
  return await axios.get(answersURL + '/' + examId, {
    headers: await authHeader(),
  });
}
async function addanswer(id, data) {
  return await axios.post(answersURL + '/' + id, data, {
    headers: await authHeader(),
  });
}


// blogs
async function getBlogs(limit, offset, status, keyword) {
  return await axios.get(blogsUrl + '?limit=' + limit + '&offset=' + offset + '&status=' + status + '&keyword=' + keyword, {
    headers: await authHeader(),
  })
}
async function createBlogs(title, shortDesc, desc1) {

  return await axios.post(blogsUrl, { title: title, shortDesc: shortDesc, desc1: desc1 }, {
    headers: await authHeader(),
  })
}
async function updateBlogdata(id, data) {
  return await axios.patch(blogsUrl + id, data, {
    headers: await authHeader(),
  })
}
async function statusUpdateBlogs(id, status) {
  return await axios.put(blogsUrl + id, { status: status }, {
    headers: await authHeader(),
  })
}

async function updateBlogsImage(id, file, type) {

  return await axios.put(blogsUrl + type + '/' + id, { file }, {
    headers: await authHeader("FormData")
  })
}


// Banner
async function getBanner() {
  return await axios.get(bannersUrl, {
    headers: await authHeader(),
  })
}
async function createBannerImage(file, type) {
  return await axios.post(bannersUrl + '/' + type, { file }, {
    headers: await authHeader('FormData'),
  })
}
async function updateBanner(id, type) {
  return await axios.patch(bannersUrl + '/' + id, { type }, {
    headers: await authHeader(''),
  })
}
async function updateBannerImage(id, file) {
  return await axios.put(bannersUrl + '/' + id, { file }, {
    headers: await authHeader('FormData'),
  })
}
async function deleteBannerData(id) {
  return await axios.delete(bannersUrl + '/' + id, {
    headers: await authHeader(''),
  })
}

/******* Podcast *******/
async function getpodcasts() {
  return await axios.get(podcastUrl + '?limit=50&offset=0', {
    headers: await authHeader(''),
  })
}
async function createpodcasts(data) {
  return await axios.post(podcastUrl, data, {
    headers: await authHeader(''),
  })
}
async function patchPodcast(id, data) {
  return await axios.patch(podcastUrl + '/' + id, data, {
    headers: await authHeader(''),
  })
}
async function updatepodcastsImage(id, image) {
  return await axios.put(podcastUrl + '/' + id, { image }, {
    headers: await authHeader('FormData'),
  })
}
async function deletePodcastStatus(id) {
  return await axios.delete(podcastUrl + '/' + id, {
    headers: await authHeader('FormData'),
  })
}

/********* Queries *********/
async function getQuery(limit, offset) {
  return await axios.get(queryUrl + '?limit=' + limit + '&offset=' + offset, {
    headers: await authHeader(),
  });
}
async function deleteQueryStatus(id) {
  return await axios.delete(queryUrl + '/' + id, {
    headers: await authHeader(),
  });
}


// Coupon
async function getAllCoupons() {
  return await axios.get(couponsUrl + '/', {
    headers: await authHeader(),
  })
}
async function addCoupon(payload) {
  return await axios.post(couponsUrl, payload, {
    headers: await authHeader(),
  });
}
async function deleteCoupon(id) {
  return await axios.delete(couponsUrl + '/' + id, {
    headers: await authHeader(),
  });
}
async function updateCoupon(id, payload) {
  return await axios.patch(couponsUrl + '/' + id, { payload }, {
    headers: await authHeader(''),
  })
}

//user

async function getAllUser() {
  return await axios.get(authURL + '/user/list', {
    headers: await authHeader(),
  })
}
async function deleteusers(id) {
  return await axios.delete(authURL + '/user/delete', {
    headers: await authHeader(),
  })
}

//TransactionHistory

async function getAllTransaction() {
  return await axios.get(transactionUrl + '/admin', {
    headers: await authHeader(),
  })
}
async function updateTransaction(payload) {
  return await axios.delete(transactionUrl + '/status', { payload }, {
    headers: await authHeader(),
  })
}

// Staff
async function getStaff(limit, offset,) {
  return await axios.get(authURL + '/staff?limit=' + limit + '&offset=' + offset, {
    headers: await authHeader(),
  })
}
async function addStaff(data) {
  return await axios.post(authURL + '/staff', data, {
    headers: await authHeader(),
  })
}
async function updateStaff(id, data) {
  return await axios.patch(authURL + '/staff/' + id, data, {
    headers: await authHeader(),
  })
}
async function deleteStaff(id) {
  return await axios.delete(authURL + '/staff/' + id, {
    headers: await authHeader(),
  })
}


// Orders
async function getorders(limit, offset, status, fromDate, toDate) {
  return await axios.get(orderURL + '/admin/orderlist?limit=' + limit + '&offset=' + offset +
    '&status=' + status + '&startDate=' + fromDate + '&endDate=' + toDate, {
    headers: await authHeader(),
  })
}
async function getOrderDetails(id) {
  return await axios.get(orderURL + '/admin/orderDetails/' + id, {
    headers: await authHeader(),
  })
}

async function downloadInvoicePdf(id) {
  return await axios.get(orderURL + '/invoice/' + id, {
    headers: await authHeader("Blob"),
    responseType: "blob",
  })
}
async function statusUpdateOrder(id, status) {
  return await axios.put(orderURL + '/admin/' + id, { status }, {
    headers: await authHeader(),
  })
}


//Total dashboard count

async function getAstologerTotal() {
  return await axios.get(dashboardURL + '/totalAstrologer/', {
    headers: await authHeader(),
  })
}

async function getUserTotal() {
  return await axios.get(dashboardURL + '/totalUsers/', {
    headers: await authHeader(),
  })
}

async function getProductsTotal() {
  return await axios.get(dashboardURL + '/totalProducts/', {
    headers: await authHeader(),
  })
}

async function getCatagoryTotal() {
  return await axios.get(dashboardURL + '/totalCatagory/', {
    headers: await authHeader(),
  })
}

async function getOrdersTotal() {
  return await axios.get(dashboardURL + '/totalOrder/', {
    headers: await authHeader(),
  })
}

async function getCouponsTotal() {
  return await axios.get(dashboardURL + '/totalCoupons/', {
    headers: await authHeader(),
  })
}

async function getBlogsTotal() {
  return await axios.get(dashboardURL + '/totalBlogs/', {
    headers: await authHeader(),
  })
}

async function getPodcastTotal() {
  return await axios.get(dashboardURL + '/totalPodcast/', {
    headers: await authHeader(),
  })
}

async function getSubCatagoryTotal() {
  return await axios.get(dashboardURL + '/totalSubCatagory/', {
    headers: await authHeader(),
  })
}

async function getCancelledOrderTotal() {
  return await axios.get(dashboardURL + '/totalOrderCancelled/', {
    headers: await authHeader(),
  })
}

async function getDispatchedOrderTotal() {
  return await axios.get(dashboardURL + '/totalOrderDispatched/', {
    headers: await authHeader(),
  })
}

async function getDeliveredOrderTotal() {
  return await axios.get(dashboardURL + '/totalOrderDelivered/', {
    headers: await authHeader(),
  })
}

/********* Bank Details *********/
async function getbankDetails(id,) {
  return await axios.get(bankDetailsURL + '/' + id, {
    headers: await authHeader(),
  })
}





export const service = {
  login,
  getPodcastTotal, getSubCatagoryTotal, getDeliveredOrderTotal, getDispatchedOrderTotal, getCancelledOrderTotal,

  getastrologer, updateastrologer, statusUpdateastrologer, statusUpdateExamAstrologer, statusUpdateVideoExamAstrologer, fetchAstrologerById,

  getexamDetails,

  getSpecialization, addspecialization, updateSpecialization, deleteSpecialization,

  getCategory, addCategory, updateCategory, statusUpdateCategory, updateCategoryImage,

  getsubCategory, addSubCategory, updatesubCategory, statusUpdatesubCategory,

  getproducts, UpdateStatusProducts,


  fetchQuestions, addquestion,

  fetchanswers, addanswer, deleteQuestion,

  getBlogs, createBlogs, updateBlogdata, statusUpdateBlogs, updateBlogsImage,

  getBanner, createBannerImage, updateBanner, updateBannerImage, deleteBannerData,

  getpodcasts, createpodcasts, patchPodcast, updatepodcastsImage, deletePodcastStatus,

  getQuery, deleteQueryStatus,

  addCoupon, getAllCoupons, deleteCoupon, updateCoupon,

  getorders, downloadInvoicePdf, getOrderDetails, statusUpdateOrder,

  getbankDetails,

  getDashboard, getAstologerTotal, getCatagoryTotal, getProductsTotal, getUserTotal, getOrdersTotal, getCouponsTotal, getBlogsTotal,

  getAllUser, deleteusers,

  getAllTransaction, updateTransaction,

  getStaff, addStaff, updateStaff, deleteStaff,


}
