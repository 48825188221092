import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Col, Card, CardHeader, Table, Form, FormGroup, Label, Input, ModalFooter, Row } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { useDispatch, useSelector } from 'react-redux';
import CommonModal from '../../Components/Modals/modal';
import Pagination from '../../Components/Pagination/Pagination';
import Empty from '../../Components/Empty/Index';
import moment from 'moment';
import { getCategory, updateCategory, addCategory, statusUpdateCategory } from '../../store/categorySlice';
import { getproducts, ModalToggle, isOpenModal, isOpenStatusModal, statusToggle, statusUpdateproducts, } from '../../store/productsSlice';
import SweetAlert from 'sweetalert2';
import { useNavigate } from 'react-router';
import CustomizerContext from '../../_helper/Customizer';


const ProductsTable = () => {
  const storeVar = useSelector(state => state.products)
  const catVar = useSelector(state => state.category)

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toggle = () => dispatch(ModalToggle());
  const statusModalToggle = () => dispatch(statusToggle());
  const { layoutURL } = useContext(CustomizerContext);
  const [stateStatus, setStateStatus] = useState('ACTIVE');
  const [submit, setSubmit] = useState(false);
  const [typingTimer, setTypingTimer] = useState(null);
  const typingDelay = 800;
  const [formVar, setFormVar] = useState({
    keyword: '',
    limit: 10,
    offset: 0,
    currentPage: 1,
    status: 'active',
    modalTitle: null,
    editState: false,
    productId: '',

    name: '',
    mrp: '',
    discountPercent: '',
    discountedPrice: '',
    finalPrice: '',
    weight: '',
    color: '',
    shape: '',
    origin: '',
    cut: '',
    desc: '',
    categoryId: '',
    subCategoryId: '',

  });

  useEffect(() => {
    dispatch(getproducts(formVar.limit, formVar.offset, formVar.status, formVar.keyword))
    dispatch(getCategory(50, 0, 'active', ''))

  }, []);

  const pageChange = (page) => {
    const offset = formVar.limit * (page - 1)
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      currentPage: page,
      offset: offset
    }))
    dispatch(getproducts(formVar.limit, offset, formVar.status, formVar.keyword))
  };
  const searchState = (e) => {
    setFormVar((prevFormVar) => ({ ...prevFormVar, keyword: e.target.value }))
    searchWithDelay(e.target.value)
  }
  const searchWithDelay = (keyword) => {
    clearTimeout(typingTimer);
    const timer = setTimeout(() => {
      dispatch(getproducts(formVar.limit, formVar.offset, formVar.status, keyword))
    }, typingDelay);
    setTypingTimer(timer);
  };
  const handleInputChange = (e) => {
    setFormVar((prevFormVar) => ({ ...prevFormVar, status: e.target.value }))
    dispatch(getproducts(formVar.limit, formVar.offset, e.target.value, formVar.keyword))
  };
  const EditToggleModal = (data) => {
    dispatch(isOpenModal(true))
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      editState: true,
      productId: data._id,
      modalTitle: 'Edit Products',
      name: data.name,

    }))
  }
  const AddToggleModal = () => {
    dispatch(isOpenModal(true))
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      editState: false,
      modalTitle: 'Add Products',
      name: '',
    }))
  }
  const onValueChange = (event) => {
    setStateStatus(event.target.value)
  }

  const submitMenu = () => {
    if (categoryNameValid()) {
      setSubmit(true)
      return null
    }
    setSubmit(false)
    if (formVar.editState) {
      dispatch(updateCategory(formVar.categoryId, { name: formVar.name }))
    } else {
      dispatch(addCategory({ name: formVar.name }))
    }
  }
  const submitStatus = () => {
    dispatch(statusUpdateproducts({ id: formVar.productId, status: stateStatus }))
  }
  const categoryNameValid = () => {
    if (!formVar.name) {
      return "Name is required";
    }
  }
  const statusToggleModal = (data) => {
    dispatch(isOpenStatusModal(true))
    setStateStatus(data.status)
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      productId: data._id,
    }))
  }

  const DeleteModel = (data) => {
    SweetAlert.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Cancel',
      reverseButtons: true
    })
      .then((result) => {
        if (result.value) {
          // dispatch(deletespecialization(data._id,))
        }
      });
  }

  const handleSelectCatChange = (event) => {
    setFormVar((prevFormVar) => ({ ...prevFormVar, catId: event.target.value }))
    // dispatch(getsubCategory('100', '0', 'ACTIVE', event.target.value, ''))
  };

  const handleDiscountedPriceChange = (e) => {
    const discountedPrice = e.target.value;
    setFormVar((prevFormVar) => ({ ...prevFormVar, discountedPrice }));
    if (formVar.mrp && discountedPrice !== "") {
      const mrp = parseFloat(formVar.mrp);
      const discountAmountValue = parseFloat(discountedPrice);
      const discountPercent = ((discountAmountValue / mrp) * 100).toFixed(2);
      const finalPrice = (mrp - discountAmountValue).toFixed(2);
      setFormVar((prevFormVar) => ({
        ...prevFormVar,
        discountPercent,
        finalPrice
      }));
    } else if (formVar.mrp && discountedPrice === "") {
      setFormVar((prevFormVar) => ({
        ...prevFormVar,
        discountPercent: 0,
        finalPrice: formVar.mrp
      }));
    }
  };

  const handleDiscountedPercentageChange = (e) => {
    const discountPercent = e.target.value;
    setFormVar((prevFormVar) => ({ ...prevFormVar, discountPercent }));

    if (formVar.mrp && discountPercent !== "") {
      const mrp = parseFloat(formVar.mrp);
      const discountPercentageValue = parseFloat(discountPercent);
      const discountedAmount = (mrp * discountPercentageValue) / 100;

      setFormVar((prevFormVar) => ({
        ...prevFormVar,
        discountedPrice: discountedAmount.toFixed(2),
      }));
    } else if (formVar.mrp && discountPercent === "") {
      setFormVar((prevFormVar) => ({
        ...prevFormVar,
        discountedPrice: '',
      }));
    }
  };


  return (
    <Fragment>
      <Col sm='12'>
        <Card>
          <CardHeader>
            <Row>
              <Col md="6">
                <Input className="form-control" placeholder='Serch..' type="text" id="yourInputId"
                  value={formVar.keyword} onChange={(e) => searchState(e)}
                />
              </Col>
              <Col md="4">
                <Input className="form-control form-control-inverse btn-square" name="select" type="select"
                  value={formVar.status} onChange={handleInputChange}>
                  <option value='active'>ACTIVE</option>
                  <option value='pending'>PENDING</option>
                </Input>
              </Col>
              <Col md="2" className='d-flex justify-content-end align-items-center'>
                <div className="text-end border-2 w-100">
                  <Btn attrBtn={{ color: 'info-gradien', className: 'w-100', size: 'sm', onClick: AddToggleModal }}>
                    Add Products
                  </Btn>
                </div>
              </Col>
            </Row>

          </CardHeader>
          <div className='table-responsive'>
            <Table hover={true} className='table-border-horizontal table-light'>
              <thead>
                <tr>
                  <th scope='col'>Sl.No</th>
                  <th scope='col'>Name</th>
                  <th scope='col'>SkuId</th>
                  <th scope='col'>Mrp</th>
                  <th scope='col'>Discount %</th>
                  <th scope='col'>Final Price</th>
                  <th scope='col'>In Stock</th>
                  <th scope='col'>Status</th>
                  <th scope='col'>Action</th>
                </tr>
              </thead>
              <tbody>
                {storeVar.productsData?.map((item, index) => (
                  <tr key={index}>
                    <th scope='row'>{index + 1}</th>
                    <td>{item.name}</td>
                    <td>{item.skuId}</td>
                    <td>{item.mrp}</td>
                    <td>{item.discountPercent}</td>
                    <td>{item.finalPrice}</td>
                    <td>{item.inStock}</td>
                    <td>
                      {
                        item.status === 'active' && <>
                          <span className={`font-success rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'active' && (
                              <i className="fa fa-check-circle-o fa-lg" aria-hidden="true"></i>
                            )}
                            &nbsp; <span>{item.status.toUpperCase()}</span>
                          </span>
                        </>
                      }
                      {
                        item.status === 'pending' && <>
                          <span className={`font-warning rounded-1 p-1 me-2 d-flex align-items-center`}>
                            {item.status === 'pending' && (
                              <i className="fa fa-clock-o fa-lg" aria-hidden="true"></i>
                            )}
                            &nbsp; <span>{item.status.toUpperCase()}</span>
                          </span>
                        </>
                      }
                    </td>
                    <td>
                      <div className='d-flex gap-2 align-items-center'>
                        <div className='cursor-pointer bg-light-primary font-primary action-icon'>
                          <i className="fa fa-edit fa-lg" onClick={(e) => EditToggleModal(item)}></i>
                          <div className="tooltipCustom">Edit</div>
                        </div>
                        <div className='cursor-pointer bg-light-primary font-info action-icon'>
                          <i className="fa fa-info-circle fa-lg"
                          // onClick={(e) => navigate('/astrologers/astroDetails/'+layoutURL +'?id='+item.id)}
                          ></i>
                          <div className="tooltipCustom">More Details</div>
                        </div>
                        <div className='cursor-pointer bg-light-primary font-success action-icon'>
                          <i className="fa fa-picture-o fa-lg"
                            onClick={(e) => navigate('/products/productImages/' + layoutURL + '?id=' + item._id)}
                          ></i>
                          <div className="tooltipCustom">Images</div>
                        </div>
                        <div className='cursor-pointer bg-light-primary font-warning action-icon'>
                          <i className="fa fa-list fa-lg"
                          // onClick={(e) => navigate('/astrologers/astroDetails/'+layoutURL +'?id='+item.id)}
                          ></i>
                          <div className="tooltipCustom">Keywords</div>
                        </div>
                        <div className='cursor-pointer bg-light-info font-info action-icon'>
                          <i className="fa fa-toggle-on fa-lg" onClick={(e) => statusToggleModal(item)} ></i>
                          <div className="tooltipCustom">Status Update</div>
                        </div>
                        <div className='cursor-pointer bg-light-info font-danger action-icon'>
                          <i className="fa fa-trash fa-lg" onClick={(e) => DeleteModel(item)}></i>
                          <div className="tooltipCustom">Delete</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          {
            storeVar.categoryData?.length <= 0 && (
              <Empty />
            )
          }
        </Card>
        {
          storeVar.totalproducts > 0 &&
          <Pagination currentPage={formVar.currentPage} totalItem={storeVar.totalproducts}
            itemsPerPage={formVar.limit} showEllipsisAfter={true} visiblePageCount={3} onPageChange={pageChange} />
        }
      </Col>
      <CommonModal isOpen={storeVar.isOpenModal} title={formVar.modalTitle} toggler={toggle} size={"xl"}>
        <Form>
          <FormGroup>
            <Row>
              <Col>
                <Label className="col-form-label" for="recipient-name">Title</Label>
                <Input className="form-control" type="text" placeholder='Enter Product Title' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, name: e.target.value }))} value={formVar.name} />
                {submit && categoryNameValid() ? <span className='d-block font-danger'>{categoryNameValid()}</span> : ""}
              </Col>
            </Row>
            <Row>

              <Col>
                <Label className="col-form-label" for="recipient-name">Category</Label>
                <Input className="form-control form-control-inverse btn-square" name="select" type="select"
                  value={formVar.catId} onChange={handleSelectCatChange}>
                  <option value="">Select Category</option>
                  {catVar?.categoryData?.map((item, index) => (
                    <option key={index} value={item._id}>
                      {item.name}
                    </option>
                  ))}
                </Input>
                {submit && categoryNameValid() ? <span className='d-block font-danger'>{categoryNameValid()}</span> : ""}
              </Col>
              <Col>
                <Label className="col-form-label" for="recipient-name">Sub Category</Label>
                <Input className="form-control form-control-inverse btn-square" name="select" type="select"
                  value={formVar.catId} onChange={handleSelectCatChange}>
                  <option value="">Select Sub Category</option>
                  {catVar?.categoryData?.map((item, index) => (
                    <option key={index} value={item._id}>
                      {item.name}
                    </option>
                  ))}
                </Input>
                {submit && categoryNameValid() ? <span className='d-block font-danger'>{categoryNameValid()}</span> : ""}
              </Col>
            </Row>
            <Row>
              <Col>
                <Label className="col-form-label" for="recipient-name">MRP</Label>
                <Input className="form-control" type="text" placeholder='Enter MRP' onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, "").replace(" ", "").slice(0, 6)} onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, mrp: e.target.value }))} value={formVar.mrp} />
              </Col>
              <Col>
                <Label className="col-form-label" for="recipient-name">Discount %</Label>
                <Input className="form-control" type="text" placeholder='Enter Discount %' onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, "").replace(" ", "").slice(0, 2)} onChange={handleDiscountedPercentageChange} value={formVar.discountPercent} />
              </Col>
            </Row>
            <Row>
              <Col>
                <Label className="col-form-label" for="recipient-name">Discoun Amount</Label>
                <Input className="form-control" type="text" placeholder='Enter Discounted Price' onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, "").replace(" ", "").slice(0, 6)} onChange={handleDiscountedPriceChange} value={formVar.discountedPrice} />
              </Col>
              <Col>
                <Label className="col-form-label" for="recipient-name">Final Price</Label>
                <Input className="form-control" type="text" placeholder='Enter Final Price' onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, "").replace(" ", "").slice(0, 6)} onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, finalPrice: e.target.value }))} value={formVar.finalPrice} />
              </Col>
            </Row>
            <Row>
              <Col>
                <Label className="col-form-label" for="recipient-name">Weight</Label>
                <Input className="form-control" type="text" placeholder='Enter Weight' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, name: e.target.value }))} value={formVar.name} />
                {submit && categoryNameValid() ? <span className='d-block font-danger'>{categoryNameValid()}</span> : ""}
              </Col>
              <Col>
                <Label className="col-form-label" for="recipient-name">Color</Label>
                <Input className="form-control" type="text" placeholder='Enter Color' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, name: e.target.value }))} value={formVar.name} />
                {submit && categoryNameValid() ? <span className='d-block font-danger'>{categoryNameValid()}</span> : ""}
              </Col>
            </Row>

            <Row>
              <Col>
                <Label className="col-form-label" for="recipient-name">Shape</Label>
                <Input className="form-control" type="text" placeholder='Enter Shape' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, name: e.target.value }))} value={formVar.name} />
                {submit && categoryNameValid() ? <span className='d-block font-danger'>{categoryNameValid()}</span> : ""}
              </Col>
              <Col>
                <Label className="col-form-label" for="recipient-name">Origin</Label>
                <Input className="form-control" type="text" placeholder='Enter Origin' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, name: e.target.value }))} value={formVar.name} />
                {submit && categoryNameValid() ? <span className='d-block font-danger'>{categoryNameValid()}</span> : ""}
              </Col>
            </Row>
            <Row>
              <Col>
                <Label className="col-form-label" for="recipient-name">Cut</Label>
                <Input className="form-control" type="text" placeholder='Enter Cut' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, name: e.target.value }))} value={formVar.name} />
                {submit && categoryNameValid() ? <span className='d-block font-danger'>{categoryNameValid()}</span> : ""}
              </Col>
              <Col>
                <Label className="col-form-label" for="recipient-name">In Stock</Label>
                <Input className="form-control" type="text" placeholder='Enter Stock Quantity' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, name: e.target.value }))} value={formVar.name} />
                {submit && categoryNameValid() ? <span className='d-block font-danger'>{categoryNameValid()}</span> : ""}
              </Col>

            </Row>
            <Label className="col-form-label" for="recipient-name">Description</Label>
            <textarea className='form-control' name='description' rows='3' placeholder='Enter Description' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, desc: e.target.value }))} value={formVar.desc} />
            {/* {submit && DescValid() ? <span className='d-block font-danger'>{DescValid()}</span> : ""} */}

          </FormGroup>
        </Form>
        <ModalFooter>
          <Btn attrBtn={{ color: 'secondary', onClick: toggle }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitMenu }}>Save Changes</Btn>
        </ModalFooter>
      </CommonModal>
      <CommonModal isOpen={storeVar.isStatusOpenModal} title={'Status'} toggler={statusModalToggle} >
        <Col>
          <div className='d-flex m-15 m-checkbox-inline justify-content-center custom-radio-ml'>
            <div className='radio radio-primary'>
              <Input id='radioinline1' type='radio' className="radio_animated" name='radio1' checked={stateStatus === 'active'} onChange={onValueChange} value='active' />
              <Label className='mb-0' for='radioinline1'>
                <span className='digits'>ACTIVE</span>
              </Label>
            </div>
            <div className='radio radio-primary'>
              <Input id='radioinline3' type='radio' className="radio_animated" name='radio3' checked={stateStatus === 'pending'} onChange={onValueChange} value='pending' />
              <Label className='mb-0' for='radioinline3'>
                <span className='digits'>PENDING</span>
              </Label>
            </div>
          </div>
        </Col>
        <ModalFooter className='justify-content-center'>
          <Btn attrBtn={{ color: 'secondary', onClick: statusModalToggle }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitStatus }}>Save Changes</Btn>
        </ModalFooter>
      </CommonModal>

    </Fragment>
  );
};

export default ProductsTable;
