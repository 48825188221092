import React, { Fragment, useEffect, useState, } from 'react';
import { Col, Card, CardHeader, Table, Form, FormGroup, Label, Input, ModalFooter, Row } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { CheckCircle, XCircle, Edit, Trash2 } from "react-feather"
import { useDispatch, useSelector } from 'react-redux';
import CommonModal from '../../Components/Modals/modal';
import { getStaff, addStaff, updateStaff, deleteStaff, isOpenModal, ModalToggle } from '../../store/staffSlice';
import Pagination from '../../Components/Pagination/Pagination';
import SweetAlert from 'sweetalert2';
import Checkbox from '../../Components/ChackBoxCell/ChackBox';


const StaffTable = () => {
  const storeVar = useSelector(state => state.staff)
  const dispatch = useDispatch();
  const toggle = () => dispatch(ModalToggle());
  const [submit, setSubmit] = useState(false);
  const [items, setItems] = useState();

  const [formVar, setFormVar] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,


    modalTitle: null,
    editState: false,

    id: '',
    name: '',
    phoneNumber: '',
    email: '',
    password: '',
    permissions: [],

  });

  useEffect(() => {
    dispatch(getStaff(formVar.limit, formVar.offset,))
  }, []);

  const AddToggleModal = () => {
    dispatch(isOpenModal(true))
    setSubmit(false)
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      editState: false,
      modalTitle: 'New Staff',
      name: '',
      phoneNumber: '',
      email: '',
      password: '',
      permissions: [],
    }))
  }

  const handleChecked = (isChecked, permission) => {
    setFormVar(prev => {
      const permissions = isChecked
        ? [...prev.permissions, permission]
        : prev.permissions.filter(item => item !== permission);
      return { ...prev, permissions };
    });
  };


  // const handleChecked = (isChecked, permission) => {
  //   if (isChecked) {
  //     setFormVar((prevFormVar) => ({
  //       ...prevFormVar,
  //       permissions: [...prevFormVar.permissions, permission],
  //     }));
  //   } else {
  //     setFormVar((prevFormVar) => ({
  //       ...prevFormVar,
  //       permissions: prevFormVar.permissions.filter((item) => item !== permission),
  //     }));
  //   }
  // };

  function setItem(setItem) {
    setItems(setItem)
  }


  const EditToggleModal = (data) => {
    dispatch(isOpenModal(true))
    setFormVar((prevFormVar) => ({
      ...prevFormVar,
      editState: true,
      modalTitle: 'Edit Staff',

      id: data._id,
      name: data.name,
      email: data.email,
      phoneNumber: data.phoneNumber,
      permissions: data.permissions,
    }))
  }


  const submitDegree = () => {
    console.log(formVar);

    if (staffNameValid() || phoneValid() || loginIdValid() || permissionValid()) {
      setSubmit(true)
      return null
    }
    setSubmit(false)

    if (formVar.editState) {
      dispatch(updateStaff(formVar.id, { name: formVar.name, email: formVar.email, phoneNumber: formVar.phoneNumber, password: formVar.password, permissions: formVar.permissions }))
    } else {
      if (staffPasswordValid()) {
        setSubmit(true)
        return null
      }
      dispatch(addStaff({ name: formVar.name, email: formVar.email, phoneNumber: formVar.phoneNumber, password: formVar.password, permissions: formVar.permissions }))
    }
  }

  const StaffDelete = (id) => {
    SweetAlert.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this staff!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      reverseButtons: true
    })
      .then((result) => {
        if (result.value) {
          dispatch(deleteStaff(id))
        }
      });
  }

  const staffNameValid = () => {
    if (!formVar.name) {
      return "Name is required";
    }
  }
  const phoneValid = () => {
    if (!formVar.phoneNumber) {
      return "Phone Number is required";
    }
  }
  const loginIdValid = () => {
    if (!formVar.email) {
      return "Login Id is required";
    }
  }
  const staffPasswordValid = () => {
    if (!formVar.password) {
      return "Password Id is required";
    }
    else if (formVar.password.length < 6) {
      return "Please Enter Password of Atleast 6 Words";
    }
  }
  const permissionValid = () => {
    if (!formVar.permissions || formVar.permissions.length === 0) {
      return "At least one permission is required";
    }
  }

  return (
    <Fragment>
      <Col sm='12'>
        <Card>
          <CardHeader>
            <Row>
              <Col md="5">
              </Col>
              <Col md="4">
              </Col>
              <Col md="3" className='d-flex justify-content-end align-items-center'>

                <div className="text-end border-2">
                  <Btn attrBtn={{ color: 'info-gradien', size: 'sm', onClick: AddToggleModal }}>
                    Add New Staff
                  </Btn>
                </div>
              </Col>
            </Row>

          </CardHeader>
          <div className='table-responsive'>
            <Table hover={true} className='table-border-horizontal table-light'>
              <thead>
                <tr>
                  <th scope='col'>Sl.No</th>
                  <th scope='col'>Name</th>
                  <th scope='col'>Login Id</th>
                  <th scope='col'>Phone Number</th>
                  <th scope='col'>Action</th>
                </tr>
              </thead>
              <tbody>
                {storeVar.staffData?.map((item, index) => (
                  <tr key={item._id}>
                    <th scope='row'>{index + 1}</th>
                    <td>{item.name} </td>
                    <td>{item.email}</td>
                    <td>{item.phoneNumber}</td>
                    <td>
                      <div className='d-flex gap-2'>
                        <div className='cursor-pointer bg-light-primary font-primary action-icon'>
                          <Edit onClick={(e) => EditToggleModal(item)} />
                          <div className="tooltipCustom">Edit</div>
                        </div>
                        <div className='cursor-pointer font-danger action-icon'>
                          <Trash2 onClick={(e) => StaffDelete(item._id)} />
                          <div className="tooltipCustom">Delete</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card>
      </Col>

      <CommonModal isOpen={storeVar.isOpenModal} title={formVar.modalTitle} toggler={toggle} size={'xl'}>
        <Form>
          <FormGroup>
            <Row>
              <Col md="6">
                <Label className="col-form-label" for="recipient-name">Staff Name</Label>
                <Input className="form-control" type="text" placeholder='Enter Fullname' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, name: e.target.value }))} value={formVar.name} />
                {submit && staffNameValid() ? <span className='d-block font-danger'>{staffNameValid()}</span> : ""}
              </Col>
              <Col md="6">
                <Label className="col-form-label" for="recipient-name">Phone Number</Label>
                <Input className="form-control" type="text" placeholder='Enter Phone Number' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, phoneNumber: e.target.value }))} value={formVar.phoneNumber} />
                {submit && phoneValid() ? <span className='d-block font-danger'>{phoneValid()}</span> : ""}
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <Label className="col-form-label" for="recipient-name">Login ID</Label>
                <Input className="form-control" type="text" placeholder='Create a Unique Login ID' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, email: e.target.value }))} value={formVar.email} />
                {submit && loginIdValid() ? <span className='d-block font-danger'>{loginIdValid()}</span> : ""}
              </Col>
              <Col md="6">
                <Label className="col-form-label" for="recipient-name">Password</Label>
                <Input className="form-control" type="text" placeholder='Create a Password' onChange={(e) => setFormVar((prevFormVar) => ({ ...prevFormVar, password: e.target.value }))} value={formVar.password} />
                {submit && staffPasswordValid() ? <span className='d-block font-danger'>{staffPasswordValid()}</span> : ""}
              </Col>
            </Row>
            <Row>
              <Label className="col-form-label">Permissions</Label>
              {["Dashboard", "Specialization", "Staff", "Exam", "Astrologer", "Category", "SubCategory", "Product", "Blogs", "Podcast", "Banners",
                "Coupons", "Orders", "Transactions", "Users", "Query"].map((permission, index) => (
                  <Col md="4" key={index}>
                    <Checkbox
                      onChecked={(isChecked) => handleChecked(isChecked, permission)}
                      item={permission}
                      setItem={setItem}
                      checked={formVar.permissions.includes(permission)}
                      disabled={false}
                    />
                  </Col>
                ))}
              {submit && permissionValid() ? <span className='d-block font-danger'>{permissionValid()}</span> : ""}

            </Row>


          </FormGroup>
        </Form>
        <ModalFooter>
          <Btn attrBtn={{ color: 'secondary', onClick: toggle }} >Close</Btn>
          <Btn attrBtn={{ color: 'primary', onClick: submitDegree }}>Save</Btn>
        </ModalFooter>
      </CommonModal>
    </Fragment >
  );
};

export default StaffTable;
